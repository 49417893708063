/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import { container, mediaQueries, weights, contentHeadings } from "../styles"

export default () => {
  const date = "制定日：2014年4月1日"
  return (
    <Layout
      headerData={{
        title: "個人情報保護方針",
        height: "400px",
        mobileMinHeight: "93vh",
        label: date,
        labelMobileOnly: true,
      }}
    >
      <div
        css={css`
          ${container.min}
          padding-top: 30px;
          margin-bottom: 60px;
          font-weight: ${weights.light};

          ${mediaQueries.phoneLarge} {
            padding-left: 0;
            padding-right: 0;
            padding-top: 80px;
            margin-bottom: 130px;
          }

          strong {
            ${contentHeadings};
            margin-bottom: 30px;
          }

          p > strong {
            margin-right: 4px;
          }
        `}
      >
        <h2
          css={css`
            display: none;

            ${mediaQueries.desktop} {
              display: block;
              ${contentHeadings};
            }
          `}
        >
          {date}
        </h2>
        <p>
          シードシステム株式会社（以下「当社」）は事業運営上扱う個人情報及び従業員等の個人情報の取扱いについて果たすべき責任とし、個人情報保護に関する法規制などに準拠した次の事項を含む個人情報保護方針を策定します。
        </p>
        <h3>個人情報保護方針</h3>
        <p>
          当社は、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
        </p>
        <h3>個人情報の安全管理</h3>
        <p>
          当社は、個人情報への不正なアクセス、または個人情報の漏洩、滅失又は毀損などの危険が起こらぬよう、技術及び組織面において、具体的ルールを定め、適切な安全対策を講じます。
        </p>
        <h3>個人情報の取扱</h3>
        <p>
          当社は、個人情報を取得するにあたり、取得目的を明らかにし本人の同意無く無断で取得・利用することはありません
        </p>
        <p>
          また法令で定める場合を除き、本人の同意に基づき取得した個人情報を、本人の事前の同意なく第三者に提供することはありません。なお、本人の求めによる個人情報の開示、訂正、追加若しくは削除又は利用目的の通知については、法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
        </p>
        <h3>個人情報の第三者提供</h3>
        <p>
          当社は、個人情報の取扱いの全部又は一部を第三者に委託する場合、その適格性を十分に審査し、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うこととします。
        </p>
        <h3>個人情報の取扱いの改善・見直し</h3>
        <p>
          当社は、個人情報の取扱い、管理体制及び取組みに関する点検を実施し、継続的に改善・見直しを行います。
        </p>
        <h3>苦情や相談の担当窓口</h3>
        <p>
          当社は、個人情報の取扱いに関する担当窓口及び責任者を以下の通り設けます。
        </p>
      </div>
    </Layout>
  )
}
